import { lazy } from 'react'
import {
    CAN_VIEW_USERS,
    CAN_AUDIT_INVOICES,
    CAN_VIEW_OWN_CONTRACTOR_PAYMENTS,
    CAN_VIEW_SITE_ANALYTICS,
} from '../../permissions'
// import MyPayments from '../../Payments/layouts/MyPayments'
import { SiteDetailsTab } from '../../../../Users/layouts/UserDetail/ui'

// Fetch these when idle, as we'll mostly likely need it
const UsersRouter = import(/* webpackChunkName: "UsersRouter", webpackPrefetch: true   */ '../../../../Users')
const UserDetail = import(
    /* webpackChunkName: "UserDetail", webpackPrefetch: true */ '../../../../Users/layouts/UserDetail'
)
const PaymentsRouter = lazy(
    () => import(/* webpackChunkName: "Payments" */ '../../../../Analytics/AnalyticsRouter'),
)
const ManagePaymentsRouter = lazy(
    () => import(/* webpackChunkName: "ManagePayments" */ '../../../../ManagePayments/ManagePaymentsRouter'),
)

export const navItems = [
    {
        label: 'My Profile',
        path: 'users/myprofile',
        icon: 'myProfile',
        component: UserDetail,
    },
    {
        label: 'My Sites',
        path: 'users/myprofile/sites',
        icon: 'mySites',
        component: SiteDetailsTab,
    },
    {
        label: 'My Payments',
        path: 'payments/user',
        icon: 'payments',
        component: PaymentsRouter,
        permission: CAN_VIEW_OWN_CONTRACTOR_PAYMENTS,
    },
    {
        label: 'Site Stats',
        path: 'payments/site',
        icon: 'stats',
        component: PaymentsRouter,
        permission: CAN_VIEW_SITE_ANALYTICS,
    },
    {
        label: 'Users',
        path: 'users',
        icon: 'users',
        component: UsersRouter,
        permission: CAN_VIEW_USERS,
    },
    {
        label: 'Manage Payments',
        path: 'manage-payments',
        icon: 'payments',
        component: ManagePaymentsRouter,
        permission: CAN_AUDIT_INVOICES,
    },
    {
        label: 'The Summit',
        path: 'sso?siteId=260',
        icon: 'huddle',
    },
    {
        label: 'Tutorials',
        path: 'sso?siteId=371&redirectPath=%2Fmy-courses%2F',
        icon: 'school',
    },
    {
        label: 'New Proposal',
        path: 'sso?siteId=371&redirectPath=%2Fbecome-a-contributor%2Fnew-proposal%2F',
        icon: 'books',
    },
]
