import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import Icon from '../../framework/Icon'

type OwnProps = {
    activeClassName?: string
    className?: string
    displayLabel: string
    icon?: string
    iconSize?: number
    textClass?: string
    path: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof NavItem.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'NavItem' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const NavItem = ({
    className,
    path,
    activeClassName: activeClassNameProp,
    icon,
    iconSize,
    textClass,
    displayLabel,
    ...rest
}: Props) => {
    const location = useLocation()

    const pathWithoutSearch = path.split('?')[0]
    const activeClassName = location.pathname.includes(pathWithoutSearch) ? activeClassNameProp : ''

    return (
        <NavLink
            className={`${className} ${activeClassName}`}
            to={path}
            activeClassName={activeClassName}
            {...rest}
        >
            {icon && <Icon icon={icon} fill="#606060" size={iconSize} />}
            <span className={textClass}>{displayLabel}</span>
        </NavLink>
    )
}

NavItem.defaultProps = {
    iconSize: null,
    icon: null,
}

export default NavItem
